import React, { useRef, useState } from "react";
import ReactDOM from "react-dom";
import FieldVisionPlayer from "@bvg/poser";
//@ts-ignore
import { fullscreenSupport } from "./fullscreenSupport";

const searchParams = new URLSearchParams(window.location.search);

function EmbeddedPlayer() {
  const isMlbDomain = parent.window.location.hostname.endsWith("mlb.com");

  // If we're not rendering within an article iframe, stop and redirect to homepage
  if (isMlbDomain && window.parent === window) {
    window.location.href =
      window.location.protocol + "//" + window.location.host;
  }

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [optedIn, setOptedIn] = useState(false);
  const containerRef = React.createRef<HTMLDivElement>();
  const controllerRef = useRef();

  const gamePk = searchParams.get("gamePk");
  const playId = searchParams.get("playId");

  if (
    gamePk === undefined ||
    gamePk === "" ||
    playId === undefined ||
    playId === ""
  ) {
    console.error(
      "gamePk and playId are required to properly render a play in FieldVision"
    );
  }

  const bearerToken = isMlbDomain
    ? //@ts-ignore
      parent.window.mlbOkta.getToken().idToken
    : null;

  if (isMlbDomain) {
    //@ts-ignore
    parent.window.mlbOkta
      .isLoggedIn()
      .then(() => setIsLoggedIn(true))
      .catch(() => setIsLoggedIn(false));
  }

  return !optedIn ? (
    <div className="buttonContainer">
      <button
        className="fvAuth"
        onClick={() =>
          isLoggedIn || !isMlbDomain
            ? setOptedIn(true)
            : //@ts-ignore
              parent.window.mlbOkta.redirectToLogin()
        }
      >
        See Gameday 3D Replay
      </button>
    </div>
  ) : isLoggedIn || !isMlbDomain ? (
    <div style={{ display: "flex", height: "100%" }}>
      <FieldVisionPlayer
        controllerRef={controllerRef}
        options={{
          gamePk: gamePk,
          playId: playId,
          trackingApiBasePath: !isMlbDomain ? "/tracking" : undefined,
          trackingApiDomain: !isMlbDomain ? window.origin : undefined,
          embeddedControls: true,
          bearerToken: bearerToken,
          batEnabled: true,
          loop: true,
          callbacks: {
            onFullscreen: fullscreenSupport(),
          },
        }}
      />
    </div>
  ) : (
    <div className="buttonContainer">
      <button
        className="fvAuth"
        //@ts-ignore
        onClick={() => parent.window.mlbOkta.redirectToLogin()}
      >
        Log in
      </button>
    </div>
  );
}

ReactDOM.render(
  React.createElement(EmbeddedPlayer),
  document.getElementById("root")
);
