let previousContainerStyle = undefined;

export const fullscreenSupport = (enteringFullscreen) =>
  // Support all the possible prefixes
  document.fullscreenEnabled ||
  document.webkitFullscreenEnabled ||
  document.mozFullScreenEnabled ||
  document.msFullscreenEnabled
    ? () => {
        if (
          !document.fullscreenElement &&
          !document.webkitFullscreenElement &&
          !document.mozFullscreenElement &&
          !document.msFullscreenElement
        ) {
          if (document.documentElement.requestFullscreen) {
            document.documentElement.requestFullscreen();
          } else if (document.documentElement.webkitRequestFullscreen) {
            document.documentElement.webkitRequestFullscreen();
          } else if (document.documentElement.mozRequestFullScreen) {
            document.documentElement.mozRequestFullScreen();
          } else if (document.documentElement.msRequestFullscreen) {
            document.documentElement.msRequestFullscreen();
          }
        } else {
          if (document.exitFullscreen) {
            document.exitFullscreen();
          } else if (document.webkitExitFullscreen) {
            document.webkitExitFullscreen();
          } else if (document.mozkitExitFullscreen) {
            document.mozkitExitFullscreen();
          } else if (document.mskitExitFullscreen) {
            document.mskitExitFullscreen();
          }
        }
      }
    : (enteringFullscreen) => {
        const iframe = window.parent.document.querySelector("#fvFrame");
        if (enteringFullscreen) {
          previousContainerStyle = {
            width: iframe.style.width,
            height: iframe.style.height,
            position: iframe.style.position,
            zIndex: iframe.style.zIndex,
            top: iframe.style.top,
            left: iframe.style.left,
          };
          iframe.style.width = "100%";
          iframe.style.height = "100%";
          iframe.style.position = "fixed";
          iframe.style.zIndex = "100";
          iframe.style.top = "0";
          iframe.style.left = "0";
          window.dispatchEvent(new Event("resize"));
        } else {
          iframe.style.width = previousContainerStyle?.width;
          iframe.style.height = previousContainerStyle?.height;
          iframe.style.position = previousContainerStyle?.position;
          iframe.style.zIndex = previousContainerStyle?.zIndex;
          iframe.style.top = previousContainerStyle?.top;
          iframe.style.left = previousContainerStyle?.left;
          window.dispatchEvent(new Event("resize"));
          previousContainerStyle = undefined;
        }
      };
